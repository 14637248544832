import React from "react";
import { useRef, useState } from "react";
import TextBox from "../TextBox/textBox.component";
import PropTypes from "prop-types";
// import Button from "../Button/button.component";
import { UploadContainer } from "./UploadInputStyles/UpladoInput.style";
import { Tooltip } from "@mui/material";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import StrinLocalized from "../../../Language/language.lang";
import { Button, Autocomplete, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import MyInputAdornmentComponent from "../InputAdorment/MyInputAdorment.component";
const UploadInput = ({
  width,
  widthButton,
  height,
  placeholder,
  uploadPic,
  index,
  DeleteFileHandle,
  isNeedDelete = false,
  noToolTip,
  downloadHandler,
  downLoadButton,
  text,
  info,
}) => {
  const longText = StrinLocalized.UploadAlert;
  const uploadRef = useRef();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <input
        type="file"
        placeholder={StrinLocalized.UploadBtn}
        style={{ display: "none" }}
        ref={uploadRef}
      />
      <UploadContainer>
        {/* <TextBox
          width={width}
          height={height}
          placeHolder={fileName ? fileName : placeholder}
          keyPress={(e) => e.preventDefault()}
          onChange={(e) => e.preventDefault()}
        /> */}
        <TextField
          type="file"
          // label={fileName ? fileName : placeholder}
          placeholder={fileName ? fileName : placeholder}
          id="outlined-basic"
          keyPress={(e) => e.preventDefault()}
          onChange={(e) => {
            setFileName(e.target.files["0"]?.name);
            setFile(e.target.files["0"]);
          }}
          variant="outlined"
          size="small"
          helperText={!noToolTip ? longText : ""}
          InputProps={{
            endAdornment: (
              <>{info ? <MyInputAdornmentComponent title={info} /> : <></>}</>
            ),
          }}
        />
        {/* <Button
          click={() => uploadRef.current.click()}
          buttonText={StrinLocalized.Upload}
          width={63}
          background="#DaD4C4"
        /> */}
        <LoadingButton
          loading={loading}
          size="large"
          onClick={async () => {
            setLoading(true);
            await uploadPic(file);
            setLoading(false);
          }}
          variant="outlined"
          startIcon={<CloudUploadIcon />}
        >
          {StrinLocalized.Upload}
        </LoadingButton>
        {isNeedDelete ? (
          // <Button
          //   buttonText={StrinLocalized.Delete}
          //   width={63}
          //   marginRight={5}
          //   click={() => DeleteFileHandle(index)}
          // />
          <Button
            size="large"
            onClick={() => DeleteFileHandle(index)}
            variant="outlined"
          >
            {StrinLocalized.Delete}
          </Button>
        ) : (
          <></>
        )}

        {downLoadButton && (
          // <Button
          //   marginRight={10}
          //   click={downloadHandler}
          //   buttonText={text}
          //   width={widthButton}
          //   // background="#DaD4C4"
          // />
          <Button size="large" onClick={downloadHandler} variant="outlined">
            {StrinLocalized.Delete}
          </Button>
        )}

        {/* {!noToolTip && (
          <Tooltip
            leaveDelay={200}
            style={{
              marginRight: "15px",
              marginTop: "5px",
              color: "grey",
            }}
            title={longText}
            placement="top"
          >
            <HelpTwoToneIcon />
          </Tooltip>
        )} */}
      </UploadContainer>
    </React.Fragment>
  );
};

UploadInput.propTypes = {
  width: PropTypes.number,
  widthButton: PropTypes.number,
  height: PropTypes.number,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  info: PropTypes.string,
  uploadPic: PropTypes.any,
  DeleteFileHandle: PropTypes.any,
  isNeedDelete: PropTypes.bool,
  index: PropTypes.number,
  noToolTip: PropTypes.bool,
  downLoadButton: PropTypes.bool,
  downloadHandler: PropTypes.func,
};

export default UploadInput;
