import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StrinLocalized from "../../Language/language.lang";
import { Controller } from "react-hook-form";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Fields, FormLabel, InputRow } from "./FormStyles/seeForm.style";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateMange from "../../components/datePicker/DateMange";
import ClockMange from "../../components/datePicker/ClockMange";
import MonthYear from "../../components/datePicker/MonthYear";
import YearPicker from "../../components/datePicker/YearPicker";
import MonthPicker from "../../components/datePicker/MonthPicker";
const ExtraFields = ({ data, control }) => {
  return (
    <Fields>
      {data &&
        data.map((item) => {
          if (item.type == "LongString" || item.type == "ShortString") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextField
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="outlined"
                      variant="outlined"
                      InputProps={{ style: { height: 60, borderRadius: 10 } }}
                      size="small"
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Date") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateMange value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "DateWithTime") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateMange
                      showTime={true}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Month") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MonthPicker value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Year") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <YearPicker value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "MonthWithYear") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MonthYear value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "DayWithMonth") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateMange value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Clock") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ClockMange value={value} onChange={onChange} />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Number") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextField
                      type="number"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="outlined"
                      InputProps={{ style: { height: 60, borderRadius: 10 } }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Price") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextField
                      type="number"
                      value={value}
                      fullWidth
                      onChange={onChange}
                      id="outlined"
                      InputProps={{ style: { height: 60, borderRadius: 10 } }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "CheckBox") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox onChange={(e, data) => onChange(data)} />
                      }
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "OneSelect") {
            let options = [];
            item.optionsField?.map((opt) =>
              options.push({ value: opt.id, label: opt.name })
            );
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={options}
                      value={value}
                      size="small"
                      onChange={(e, data) => onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { height: 60, borderRadius: 10 },
                          }}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "MultiSelect") {
            let options = [];
            item.optionsField?.map((opt) =>
              options.push({ value: opt.id, label: opt.name })
            );
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      multiple
                      disablePortal
                      id="combo-box-demo"
                      options={options}
                      value={value}
                      size="small"
                      onChange={(e, data) => onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { height: 60, borderRadius: 10 },
                          }}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Tell") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextField
                      type="tel"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      id="outlined"
                      InputProps={{ style: { height: 60, borderRadius: 10 } }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </InputRow>
            );
          } else if (item.type == "Address") {
            return (
              <InputRow key={item.id}>
                <FormLabel>{item.name}</FormLabel>
                <Controller
                  key={item.id}
                  name={`extra_${item.name}`}
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <TextField
                      value={value}
                      fullWidth
                      onChange={onChange}
                      id="outlined"
                      variant="outlined"
                      InputProps={{ style: { height: 60, borderRadius: 10 } }}
                      size="small"
                    />
                  )}
                />
              </InputRow>
            );
          }
        })}
    </Fields>
  );
};
ExtraFields.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  control: PropTypes.any,
};
export default ExtraFields;
