import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker"; // تغییر به TimePicker
import moment from "moment-jalaali";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Proptypes from "prop-types";

const ClockMange = ({ onChange, value, label }) => {
  // تابع هندل تغییر زمان
  const handleTimeChange = (newValue) => {
    const formattedValue = newValue ? moment(newValue).format("HH:mm:ss") : "";
    onChange(formattedValue); // برگرداندن زمان انتخاب‌شده به فرمت HH:mm:ss
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DemoContainer
          sx={{ paddingTop: 0, overflow: "visible" }}
          components={["TimePicker"]}
        >
          <TimePicker
            sx={{ width: "571px" }}
            slotProps={{ textField: { size: "small" } }}
            label={label}
            value={value ? moment(value, "HH:mm:ss") : null} // مقدار اولیه در فرمت HH:mm:ss
            onChange={handleTimeChange}
            ampm={false} // حالت 24 ساعته
          />
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default ClockMange;

ClockMange.propTypes = {
  onChange: Proptypes.func.isRequired, // تابع برای هندل تغییرات
  value: Proptypes.string, // مقدار اولیه به صورت رشته
  label: Proptypes.string, // لیبل فیلد
  onBlur: Proptypes.bool,
};
