// context/HeaderContext.js
import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerData, setHeaderData] = useState({ avatar: "", search: "" });

  return (
    <HeaderContext.Provider value={{ headerData, setHeaderData }}>
      {children}
    </HeaderContext.Provider>
  );
};

// تعریف PropTypes برای HeaderProvider
HeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};
