import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { MdOutlineInfo } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MyInputAdornmentComponent = ({ title }) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <InputAdornment position="end">
      <Tooltip title={open ? title : ""} arrow open={open}>
        <IconButton onClick={handleClick} ref={iconRef}>
          <MdOutlineInfo />
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

MyInputAdornmentComponent.propTypes = {
  title: PropTypes.string,
};

export default MyInputAdornmentComponent;
