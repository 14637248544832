import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Proptypes from "prop-types";
import moment from "moment-jalaali";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";

const DayMonth = ({ onChange, value, label }) => {
  moment.loadPersian({ dialect: "persian-modern" });
  const language = localStorage.getItem("lang");

  return (
    <>
      {language === "FA" ? (
        <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={["DatePicker"]}
          >
            <DatePicker
              views={["month", "day"]} // نمایش فقط سال و ماه
              sx={{ width: "100%" }}
              slotProps={{ textField: { size: "small" } }}
              label={label}
              value={value ? moment(value) : undefined}
              onChange={onChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={["DatePicker"]}
          >
            <DatePicker
              views={["month", "day"]} // نمایش فقط سال و ماه
              sx={{ width: "100%" }}
              slotProps={{ textField: { size: "small" } }}
              label={label}
              value={value ? dayjs(value) : undefined}
              onChange={onChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      )}
    </>
  );
};

export default DayMonth;

DayMonth.propTypes = {
  onChange: Proptypes.func.isRequired,
  value: Proptypes.string,
  label: Proptypes.string,
};
