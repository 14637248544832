import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Link,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StrinLocalized from "../../Language/language.lang";

const SuccessPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f4f6f8",
      }}
    >
      <Card sx={{ padding: 4, textAlign: "center", maxWidth: 400 }}>
        <CheckCircleIcon sx={{ fontSize: 48, color: "green", mb: 2 }} />
        <Typography variant="h5" component="h1" gutterBottom>
          {StrinLocalized.SuccessMessageFormMakerSubmit}
        </Typography>
        {/* <Link href="https://your-website.com" underline="none" sx={{ display: 'block', mb: 2 }}>
          Go back
        </Link>
        <Button
          variant="contained"
          href="https://your-website.com"
        >
          Go to Homepage
        </Button> */}
      </Card>
    </Box>
  );
};

export default SuccessPage;
